import { Grid } from "@mui/material";
import YoutubeEarnings from "./YoutubeEarnings";
import Media from "./Media";
import Branded from "./Branded";
import CreatorServices from "./CreatorServices";
import Discover from "./Discover";
import Resume from "./Resume";
import "./style.scss";
import ShortsAds from "../SideAds/ShortsAds";
import LibraryAds from "../SideAds/LibraryAds";

const Feed = () => {
  return (
    <Grid container spacing={2}>
        <Resume/>
        <YoutubeEarnings/>
        <Grid item width={1} sx={{display:{md: "none", xs: "flex"}}}>
            <ShortsAds/>
        </Grid>
        <Media/>
        <Grid item width={1} sx={{display:{md: "none", xs: "flex"}}}>
          <LibraryAds/>
        </Grid>
        <Branded/>
        {/* <CreatorServices/>
        <Discover/> */}
    </Grid>
  );
};

export default Feed;
