import {Grid}  from "@mui/material";
import SideAds from "./SideAds";
import Feed from "./Feed";

const Dashboard = () => {
    return (
        <>
        <Grid container spacing={2} sx={{mb: 2}}>
            <Grid item xs={12} md={8} flexDirection={"column"}>
                <Feed/>
            </Grid>
            <Grid item xs={12} md={4} sx={{display: {xs: "none", md: "block"}}}>
                <SideAds/>
            </Grid>
        </Grid>
        </>
    );
};

export default Dashboard;