import {Autocomplete, Box, TextField} from "@mui/material";
import {genders} from "../../models/Genders";

interface GenderDropDownProps {
    initialValue: number;
    updateValue: (val: number ) => void;
    error: boolean | undefined;
    onBlur: () => void;
    helperText: string;
    disabled: boolean;
}

const GenderDropDown = (props: GenderDropDownProps) => {

    const getSelectedGender = () => {
        const ig = genders.find((g) => g.code === props.initialValue*1);
        return ig ? ig : null;
    };

    return (
        <Autocomplete
            id="gender-select"
            options={genders}
            autoHighlight
            getOptionLabel={(option) => option.label}
            fullWidth
            disabled={props.disabled}
            value={getSelectedGender()}
            onChange={(event, value) => {
                if (value === null)
                    props.updateValue(-1);
                else
                    props.updateValue(value.code);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.icon} {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Gender"
                    error={props.error}
                    onBlur={props.onBlur}
                    helperText={props.helperText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />

    );
};

export default GenderDropDown;