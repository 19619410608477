import {Box, Typography} from "@mui/material";
import GoogleAuthButton from "components/MainPanel/GoogleAuthButton/GoogleAuthButton";
import { BentPixelsUserInfo } from "@models/BentPixelsUserInfo";
import { useSignIn } from 'react-auth-kit';
import {AxiosError, AxiosResponse} from "axios";
import {getUserExists, getUserExistsById} from "../../../services/AuthServices";
import { CurrentUserInfo } from "utils/hooks/useUserInfo";
import { useContext } from "react";
import {CurrentMainSnackbar} from "../../../hooks/useMainSnackbar";


function Unlogged() {
    const signIn = useSignIn();
    const { user, logout } = useContext(CurrentUserInfo);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const setSingIn = (userInfo?: BentPixelsUserInfo) => {
        // verify if exist
        getUserExists(userInfo?.email, userInfo?.token).then((response) => {
            if(userInfo && response.status === 200) {
                if(signIn({
                    token: userInfo.token,
                    expiresIn: 3600,
                    tokenType: "Bearer",
                    authState: {
                        ...userInfo
                    },
                })) {
                    localStorage.setItem('authToken', userInfo.token);
                    window.location.href = '/home/dashboard';
                }
            } else {
                updateMainSnackbar(true, "error", "Creator not found. We're redirecting you to the onboarding page...");
                setTimeout(() => {
                    window.location.href = '/onboarding';
                }, 3000);
            }
        }).catch((error) => {
            console.log(error);
        });
    };
    const showErrorAlert = (error: AxiosError) => {
        console.log(error);
    };
    return (
        <>
            <Typography
                variant="h4" gutterBottom
                mb={3}
                sx={{
                    textAlign: "center",
                    fontSize: "34px",
                    lineHeight: "123.5%",
                    letterSpacing: "0.25px"
                }}>
                Sign in
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textAlign: "center",}}>
                Sign in with GAuth for full account access and benefits.
            </Typography>
            <Box mt={6} sx={{justifyContent:"center", alignItems:"center"}}>
                <div style={{'width': "278px", "margin": "0 auto"}}>
                    <GoogleAuthButton onSuccessfulAuth={setSingIn} onError={showErrorAlert} />
                </div>
            </Box>
        </>
    );
}

export default Unlogged;