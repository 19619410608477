import { User, UserInfo } from "@models/User";
import { createContext, useState } from "react";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import { ACCESS_TOKEN } from "../constants/login";
import { TalentManager } from "@models/TalentManager";

export const CurrentUserInfo = createContext<UserInfo>({
  user: undefined,
  talentManager: undefined,
  authenticated: false,
  updateUser: () => Promise.resolve(false),
  updateTalentManager: () => Promise.resolve(false),
  logout: () => undefined,
});

export const useUserInfo = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [talentManager, setTalentManager] = useState<TalentManager | undefined>(undefined);
  const [authenticated, setAuthenticated] = useState(false);
  const [authInterceptor, setAuthInterceptor] = useState<number | undefined>(
    undefined
  );
  const updateUser = (aUser: User | undefined): Promise<boolean> => {
    if (aUser !== undefined) {
      setUser(aUser);
      setAuthenticated(true);
      setAuthInterceptor(
        axios.interceptors.request.use(
          (config: AxiosRequestConfig) => {
            if (config.headers) {
              config.headers.Authorization = `Bearer ${localStorage.getItem(
                ACCESS_TOKEN
              )}`;
            }
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        )
      );
      axios.interceptors.response.use(
        (res: AxiosResponse) => {
          return res;
        },
        (error: AxiosError) => {
          if (error.response?.status === 401) {
            logout();
          }
        }
      );
    } else {
      setUser(undefined);
      setAuthenticated(false);
      if (authInterceptor !== undefined) {
        axios.interceptors.request.eject(authInterceptor);
      }
    }
    return Promise.resolve(true);
  };
  
  const updateTalentManager = (tm: TalentManager | undefined): Promise<boolean> => {
    if (tm !== undefined) {
      setTalentManager(tm);
    }
    return Promise.resolve(true);
  };

  const logout = () => {
    setUser(undefined);
    setAuthenticated(false);
    localStorage.removeItem(ACCESS_TOKEN);
  };
  return { user, talentManager, authenticated, updateUser, updateTalentManager, logout };
};
