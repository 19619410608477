import {useContext, useState} from 'react';
import {Box, Grid} from "@mui/material";
import Footer from "./Footer/Footer";
import MainContent from "./MainContent/MainContent";
import {CurrentStepContext} from "../../hooks/useStep";
import {steps} from "../LeftPanel/StepInstructions/StepInstructionList";

const MainPanel = () => {
    const {step} = useContext(CurrentStepContext);
    const [continueEnabled, setContinueEnabled] = useState(false);
    const [submit, setSubmit] = useState(false);
    const footerShadow = "0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)";
    return (
        <Box sx={{height: "100%"}}>
            <Grid container direction='column' justifyContent={'space-between'} flexWrap="wrap" sx={{height: "100%", alignItems: "stretch"}}>
                <Grid item sx={{backgroundColor: "#F0F1FF", pt: {xs: "16px", md:"80px"}, flexBasis: 1, flexGrow: 1}}>
                    <MainContent updateContinueButtonStatus={setContinueEnabled} submitValues={submit} setSubmit={setSubmit}/>
                </Grid>
                {step !== steps.length-1 ?
                    <Grid item sx={{
                        position: {xs: "absolute", md: "relative"},
                        bottom: {xs: "0", md: "auto"},
                        left: {xs: "0", md: "auto"},
                        width: {xs: "100%", md: "auto"},
                        boxShadow: {xs: footerShadow, md:"0"},
                        zIndex: 1
                        }}>
                        <Footer continueEnabled={continueEnabled} shouldSubmit={(s: boolean) => setSubmit(s)}/>
                    </Grid>
                    : <></>
                }
            </Grid>
        </Box>
    );
};

export default MainPanel;