import {createContext, useState} from 'react';

interface responsiveInside {
    responsiveInsideFlag: boolean
    setResponsiveInsideFlag: (responsiveInsideFlag: boolean) => void
}

export const CurrentResponsiveInsideFlag = createContext<responsiveInside>({responsiveInsideFlag: false, setResponsiveInsideFlag: () => undefined});

export const useResponsiveInsideFlag = () => {
    const [responsiveInsideFlag, setFlag] = useState(false);
    const setResponsiveInsideFlag = (newFlag: boolean) => {
        setFlag(newFlag);
    };
    return {responsiveInsideFlag, setResponsiveInsideFlag};
};