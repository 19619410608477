import {YoutubeSummary} from "@models/YoutubeSummary";
import {BentPixelsUserInfo} from "@models/BentPixelsUserInfo";
import DetailedYoutubeAccounts from "../YoutubeSteps/DetailedYoutubeAccounts/DetailedYoutubeAccounts";
import {Box, Typography} from "@mui/material";
import AppFooter from "../../AppFooter/AppFooter";
import UserInfoReview from "../UserInfoSteps/UserInfoReview";

interface ReviewProps {
    youtubeAccounts: YoutubeSummary[];
    userInfo: BentPixelsUserInfo;
}

const Review = (props: ReviewProps) => {
    return (
        <>
            <Typography variant={'h4'} sx={{color: "#14A81C", pb: 3}}>Done!</Typography>
            <Typography variant={'body1'} sx={{pb: 5}}>Thank you for applying! We will reach out if you are
                accepted.</Typography>
            <Box sx={{mb: 5}}>
                <UserInfoReview userInfo={props.userInfo} />
            </Box>
            <Box>
                <DetailedYoutubeAccounts youtubeAccounts={props.youtubeAccounts}/>
            </Box>
            <Box sx={{pt: 5, display:{xs: "none", md:"flex"}}}>
                <AppFooter/>
            </Box>
        </>
    );
};

export default Review;