import { RefreshOutlined, YouTube } from "@mui/icons-material";
import {Avatar, Button, Grid, Paper, Typography}  from "@mui/material";

const YoutubeChannelList = () => {
    return (
        <>
            <Paper sx={{background: "rgba(10, 42, 231, 0.08)", mb: 1, borderRadius: 0}}>
                <Typography variant="body2" sx={{p:1}}>
                    <YouTube sx={{color: "rgba(224, 47, 47, 1)", verticalAlign: "middle", mr: 1}}/>
                    Youtube
                </Typography>
            </Paper>
            <Grid container sx={{justifyContent: "space-between",mt:2, p: 1}}>
                <Grid item xs={1} sx={{textAlign: "center", mr: 2}}>
                    <Avatar alt="Remy Sharp" src="https://loremflickr.com/40/40/women" sx={{display: "inline-block"}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="base16" sx={{display: "block"}}>{"<Channel Name>"}</Typography>
                    
                    <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>1,232,440 subscribers</Typography>
                </Grid>
                <Grid item xs={3} sx={{textAlign: "right"}}>
                    <Button variant="outlined" color="success" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, padding: "0 8px"}}>Connected</Button>
                </Grid>
            </Grid>
            <Grid container sx={{justifyContent: "space-between",mt:2, p: 1}}>
                <Grid item xs={1} sx={{textAlign: "center", mr: 2}}>
                    <Avatar alt="Remy Sharp" src="https://loremflickr.com/40/40/male" sx={{display: "inline-block"}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="base16" sx={{display: "block"}}>{"<Channel Name>"}</Typography>
                    
                    <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>1,232,440 subscribers</Typography>
                </Grid>
                <Grid item xs={3} sx={{textAlign: "right"}}>
                    <Button variant="outlined" color="error" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, padding: "0 8px"}}>
                        Re-connect
                        <RefreshOutlined sx={{fontSize: "14px", ml: "4px"}}/>
                    </Button>
                </Grid>
            </Grid>
            <Grid container sx={{justifyContent: "space-between",mt:2, p: 1}}>
                <Grid item xs={1} sx={{textAlign: "center", mr: 2}}>
                    <Avatar alt="Remy Sharp" src="https://loremflickr.com/40/40/women" sx={{display: "inline-block"}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="base16" sx={{display: "block"}}>{"<Channel Name>"}</Typography>
                    
                    <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>1,232,440 subscribers</Typography>
                </Grid>
                <Grid item xs={3} sx={{textAlign: "right"}}>
                    <Button variant="outlined" color="success" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, padding: "0 8px"}}>Connected</Button>
                </Grid>
            </Grid>
            <Grid container sx={{justifyContent: "space-between",mt:2, p: 1}}>
                <Grid item xs={1} sx={{textAlign: "center", mr: 2}}>
                    <Avatar alt="Remy Sharp" src="https://loremflickr.com/40/40/women" sx={{display: "inline-block"}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="base16" sx={{display: "block"}}>{"<Channel Name>"}</Typography>
                    
                    <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>1,232,440 subscribers</Typography>
                </Grid>
                <Grid item xs={3} sx={{textAlign: "right"}}>
                    <Button variant="outlined" color="success" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, padding: "0 8px"}}>Connected</Button>
                </Grid>
            </Grid>
        </>
    );
};

export default YoutubeChannelList;