import { InfoOutlined } from "@mui/icons-material";
import { Paper, Button } from "@mui/material";
import { Link } from "react-router-dom";

const LibraryAds = () => {
    return (
        <>
            
            <Paper sx={{br: .5, background: "#00E676", p: 3, mb: 3, color:"#20272E"}}>
                <p style={{color: "rgba(32, 39, 46, 0.60)", padding: 0}}>
                    Library licensing 
                    <InfoOutlined sx={{fontSize:"14px", ml: 1}}/>
                </p>
                <p style={{fontSize: "22px", fontWeight: "600", padding: "0px 30px 0px 0px", position: "relative"}}>
                    We&apos;ve reviewed your account and your library you are eligible to receive $300,000 payment.
                    <span style={{position: "absolute", top: "0", right: "-6px", fontSize: "32px"}}>
                        👩🏻‍💻
                    </span>
                </p>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{color: "#20272E", borderColor:"#20272E", padding: "6px 24px", fontWeight: "600"}}
                    component={Link} to="https://bentpixels.com/about#contact" target="_blank">
                    Get Started
                </Button>
            </Paper>
        </>
    );
};

export default LibraryAds;