import {ReactNode, useContext, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Box, CircularProgress, Dialog, Typography} from "@mui/material";
import Youtube from "../YoutubeSteps/Youtube";
import {YoutubeSummary} from "../../../models/YoutubeSummary";
import {CurrentStepContext} from "../../../hooks/useStep";
import useYoutubeAccounts from "../../../hooks/useYoutubeAccounts";
import AuthSteps from "../AuthSteps/AuthSteps";
import UserInfoSteps from "../UserInfoSteps/UserInfoSteps";
import {BentPixelsUserInfo} from "@models/BentPixelsUserInfo";
import {CurrentReviewStep} from "../../../hooks/useInfoStepReview";
import CustomApplicationError, {ErrorType} from "./CustomApplicationError";
import DetailedYoutubeAccounts from "../YoutubeSteps/DetailedYoutubeAccounts/DetailedYoutubeAccounts";
import AppFooter from "../../AppFooter/AppFooter";
import Review from "../Review/Review"; 
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

interface MainContentProps {
    updateContinueButtonStatus: (x: boolean) => void;
    submitValues: boolean;
    setSubmit: (x: boolean) => void;
}

const MainContent = (props: MainContentProps) => {
    const {reviewStep, updateReviewStep} = useContext(CurrentReviewStep);
    const {step, updateStep} = useContext(CurrentStepContext);
    const [renderedComponent, setRenderedComponent] = useState<ReactNode>(<></>);
    const [searchParams, setSearchParams] = useSearchParams();
    const {addAccount, youtubeAccounts, removeAccount} = useYoutubeAccounts();
    const [emailAccessToken] = useState('');
    const [fetchData, setFetchData] = useState(true);
    const [existingAccount, setExistingAccount] = useState(false);
    const [requiredYtChannels, setRequiredYtChannels] = useState<YoutubeSummary[]>([]);
    const [customApplicationError, setCustomApplicationError] = useState<ErrorType | null>(null);
    const [userInfo, setUserInfo] = useState<BentPixelsUserInfo>({
        creatorId: '',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        gender: -1,
        language: 0,
        dob: '',
        phoneNumber: '',
        photo: '',
        token: ''
    });

    const closeCustomApplicationErrorModal = (action: string) => {
            if (action !== 'backdropClick') {
                setCustomApplicationError(null);
            }
        };

    const handleSubmissionFailure = (errorType: ErrorType, errorReason: string) => {
        console.log(errorType + ": " + errorReason);
        setCustomApplicationError(errorType);
        if (errorType === "applicationSubmission") { // don't show submission successful screen
            updateStep(step - 1);
        }
        props.setSubmit(false);
    };

    useEffect(() => {
            if (props.submitValues) {
                const config: AxiosRequestConfig = {method: 'post', url: '', data: userInfo};
                if (!userInfo.creatorId) {
                    config.method = 'post';
                    config.url = '/api/creator/apply';
                } else {
                    config.method = 'put';
                    config.url = `/api/creator/${userInfo.creatorId}`;
                }
                // for custom application, only add channels to CRM that aren't already there
                let youtubeAccountsToAdd = youtubeAccounts;
                if (requiredYtChannels.length > 0) {
                    const requiredChannelIds = requiredYtChannels.map(c => c.channelId);
                    youtubeAccountsToAdd = youtubeAccountsToAdd.filter(account => !requiredChannelIds.includes(account.channelId));
                }
                // TODO: is there a nicer way to do this instead of within an if/else?
                // if (youtubeAccountsToAdd.length > 0) {
                //     axios.request(config)
                //         .then((res: AxiosResponse<string>) => axios
                //             .post(`/api/creator/${res.data}/socials/apply`, youtubeAccountsToAdd)
                //             .then(() => props.setSubmit(false))
                //             .catch((err) => handleSubmissionFailure("channelSubmission", "error submitting socials (YT channels): " + err))
                //         )
                //         .catch(err => handleSubmissionFailure("applicationSubmission", "error submitting application: " + err));
                // } else {
                //     axios.request(config)
                //         .then(() => props.setSubmit(false))
                //         .catch(err => handleSubmissionFailure("applicationSubmission", "error submitting application: " + err));
                // }
                axios.request(config)
                    .then((res: AxiosResponse<string>) => {
                        const request = youtubeAccountsToAdd.length > 0
                            ? axios.post(`/api/creator/${res.data}/socials/apply`, youtubeAccountsToAdd)
                            : Promise.resolve();
                        
                        request
                            .then(() => props.setSubmit(false))
                            .catch((err) => handleSubmissionFailure("channelSubmission", "error submitting socials (YT channels): " + err));
                    })
                    .catch(err => handleSubmissionFailure("applicationSubmission", "error submitting application: " + err));
            }
        }, [props.submitValues]
    );

    const onSuccessfulAuthentication = (user: BentPixelsUserInfo | undefined) => {
        if (userInfo.creatorId) {
            const data = userInfo;
            data.token = !data.token && user?.token ? user?.token : '';
            data.photo = !data.photo && user?.photo ? user?.photo : '';
            setUserInfo(data);
        }
        else if (user !== undefined) {
            setUserInfo(user);
        }
        updateStep(step + 1);
    };

    const creatorApplicationId = searchParams.get('applicationId');
    useEffect(() => {
            if (step == 0 && creatorApplicationId != null) {
                 axios.get(`/api/creator/application/${creatorApplicationId}`)
                    .then(res => {
                        switch (res.data.applicationOnboardingStatus.toUpperCase()) {
                            case "PROSPECT":
                                setRequiredYtChannels(res.data.channels);
                                console.log(res.data, res.data.birthdate);
                                setUserInfo({
                                    creatorId: res.data.creatorId ?? '',
                                    firstName: res.data.firstname ?? '',
                                    lastName: res.data.lastname ?? '',
                                    email: res.data.emailaddress1 ?? '',
                                    country: res.data.country ?? '',
                                    gender: res.data.gender ?? '',
                                    language: res.data.language ?? '',
                                    dob: res.data.birthdate ?? '',
                                    phoneNumber: res.data.phoneNumber ?? '',
                                    photo: res.data.photo ?? '',
                                    token: res.data.token ?? ''
                                });
                                // TODO: use the result data to populate fields in Step 3 (no need to call /api/creator/${userInfo.email}/exists in this case)
                                break;
                            default:
                                console.log("ERROR: application (%s) has already been submitted", creatorApplicationId);
                                setCustomApplicationError('alreadySubmitted');
                                break;
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 404) {
                            console.log("ERROR: application with id %s not found", creatorApplicationId);
                            setCustomApplicationError('notFound');
                        } else {
                            console.log("ERROR while reading existing creatorApplication: " + err);
                            setCustomApplicationError('unknown');
                        }
                    });
            }
        }, [step]
    );

    useEffect(() => {
        let component: ReactNode = <></>;
        switch (step) {
            case 0: {
                component =
                    <Youtube addAccount={addAccount} youtubeAccounts={youtubeAccounts} removeAccount={removeAccount}
                    requiredChannels={requiredYtChannels}
                    canProceedToNextStep={props.updateContinueButtonStatus} />;
                break;
            }
            case 1: {
                component = <AuthSteps onSuccessfulAuth={onSuccessfulAuthentication}/>;
                updateReviewStep('about');
                setFetchData(true);
                break;
            }
            case 2: {
                if (reviewStep === 'about') {
                    component = <UserInfoSteps
                        emailAccessToken={emailAccessToken}
                        canSubmit={props.updateContinueButtonStatus}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        fetchData={fetchData}
                        setFetchData={(x: boolean) => setFetchData(x)}
                        setExistingAccount={(existing: boolean) => setExistingAccount(existing)}
                    />;
                } else {
                    component = <DetailedYoutubeAccounts youtubeAccounts={youtubeAccounts}/>;
                }
                break;
            }
            case 3: {
                if (props.submitValues) {
                    component = <Dialog open={props.submitValues}>
                        <Box sx={{py: 2, px: 3, display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Submitting Application
                            </Typography>
                            <CircularProgress sx={{my: 3}} />
                        </Box>
                    </Dialog>;
                } else {
                    component = <Review userInfo={userInfo} youtubeAccounts={youtubeAccounts}/>;
                }
            }
        }
        setRenderedComponent(component);
    }, [step, youtubeAccounts, userInfo, reviewStep, emailAccessToken, props.submitValues, requiredYtChannels]);

    useEffect(() => {
        switch (step) {
            case 0: { // continue button logic for this step handled within Youtube.tsx
                break;
            }
            case 1: {
                props.updateContinueButtonStatus(false);
                break;
            }
            case 2: {
                break;
            }
            default: {
                props.updateContinueButtonStatus(true);
            }
        }
    }, [youtubeAccounts, step, props]);

    return (
        <Box sx={{display: 'flex', justifyContent: "center", alignItems: "center"}}>
            <Box sx={{width: {xs: 1, md: .75}, mx: "auto", px:{xs: 2, md:0}}}>
                <CustomApplicationError open={customApplicationError !== null} errorType={customApplicationError} handleClose={closeCustomApplicationErrorModal} applicationId={creatorApplicationId}/>
                {renderedComponent}
                <AppFooter sx={{display:{xs: "block", md: "none"},pt:"24px", fontSize: "10px", lineHeight: "14px"}}/>
            </Box>
        </Box>
    );
};

export default MainContent;