import LibraryAds from "./LibraryAds";
import ShortsAds from "./ShortsAds";


const SideAds = () => {
    return (
        <>
            <ShortsAds/>
            <LibraryAds/>
        </>
    );
};

export default SideAds;