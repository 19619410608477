import {Box,Button, Grid, useTheme}  from "@mui/material";
import Logo from "../Logo/logo";
import OffLineIndicator from "./OffLineIndicator";
import HeaderAlert from "./HeaderAlert";
import { useContext } from "react";
import { CurrentResponsiveMenuFlag } from "../../hooks/useResponsiveFlag";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentResponsiveInsideFlag } from "../../hooks/useResponsiveInsideFlag";
import { CurrentUserInfo } from "utils/hooks/useUserInfo";

const AppHeader = () => {
    const theme = useTheme();
    const colors = theme.palette.primary;
    const {responsiveMenuFlag, setResponsiveMenuFlag} = useContext(CurrentResponsiveMenuFlag);
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const { user, authenticated } = useContext(CurrentUserInfo);
    const changeFlag = (flag: boolean) => {
        setResponsiveMenuFlag(!flag);
        setResponsiveInsideFlag(false);
    };

    return (
        <>
        <Grid item xs={12} sx={{ borderBottom: "1px solid rgba(10, 42, 231, 0.12)", position: "fixed", top: 0, width: "100%", zIndex: "1"}}>
            <Box sx={{
                display: "flex",
            pl: 0.5, pt: 0.1, 
            height: {xs: 48, md: 80}, 
            alignItems: "center", 
            boxSizing: "border-box",
            borderBottom: "1px solid #3550F71F",  
            background: "#0A2AE7"}}>
                <Button sx={{display: {xs: 'flex', md: 'none'}, py: 0}} onClick={() => changeFlag(responsiveMenuFlag)}>
                    {
                        !responsiveMenuFlag ?
                            <MenuIcon sx={{color:"#FFF"}} />
                        :
                            <CloseIcon sx={{color:"#FFF"}} />
                    }
                </Button>
                <Logo sx={{
                    width:{xs: "100vw", md:"auto"},
                    display: "inline-flex",
                    borderBottom: "1px solid #3550F71F",
                    boxSizing: "border-box",
                    fill: {xs: colors.contrastText, md: colors.contrastText},
                    pl:{xs: 1, md: 3 }}}/>
            </Box>
            <HeaderAlert text="You might be eligible for a $500K licensing deal." show={window.location.pathname.includes('home')} emoji={'🏆'} bgColor="#FFC107"/>
            <OffLineIndicator/>
        </Grid>
        <HeaderAlert text="You might be eligible for a $500K licensing deal." show={window.location.pathname.includes('home')} emoji={'🏆'} bgColor="#FFC107"/>
        <OffLineIndicator/>
        </>
    );
};

export default AppHeader;