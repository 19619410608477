import { ChevronLeft } from "@mui/icons-material";
import {Button, Paper, Typography}  from "@mui/material";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext } from "react";
import FileDownload from 'js-file-download';
import { getDownloadData } from "services/UserServices";

const DownloadYourData = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const downloadSettingsData = () => {
        getDownloadData().then((res: any) => {
            FileDownload(res.data, 'bpOS-data.json');
        });
    };
    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Download your data
        </Typography>
        <Paper sx={{p: 3, br: 0.5}}>
            <Typography variant="base20" sx={{display: "flex", fontWeight: "500", justifyContent: "space-between", }}>
                Download your information
            </Typography>
            <Typography variant="baseCaption" sx={{color: "#20272E", lineHeight: "143%", mt: 1, mb: 3}}>
                At any given moment, you have the option to acquire a personal copy of your bpOS data. This can be done by downloading either a comprehensive copy of all your information or selectively choosing specific types of data and date ranges that you desire.
            </Typography>
            <Button variant="outlined" sx={{width: "100%"}} onClick={downloadSettingsData}>
                DOWNLOAD DATA
            </Button>
        </Paper>
        </>
    );
};

export default DownloadYourData;