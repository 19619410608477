import {Grid, Typography}  from "@mui/material";

const RevShares = () => {
    return (
        <>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator PartNER Provided (%)</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>80.00%</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator PAID FEATURES (%)</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>80.00%</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Creator UGC (%)</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>70.00%</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>SHORTS Revenue (%)</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>-%</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Direct Sold Media (%)</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>80.00%</Typography>
            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Withhold Tax</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>0.00</Typography>
            </Grid>
        </>
    );
};

export default RevShares;