import axios, { AxiosPromise } from "axios";

export const getUserExists = (email?: string, token?: string): AxiosPromise => {
    return axios.get(`/api/creator/${email}/exists`, {
        headers: {
            Authorization: `Bearer casacascascascas ${token}`,
        },
    });
};

export const loginGoogle = (token: string): AxiosPromise => {
    return axios.post(
        `/api/login`,
        {},
        {
            headers: {
                "X-GOOGLE-RES": `${token}`,
            },
        }
    );
};

export const getGoogleEndpoint = (): AxiosPromise => {
    return axios.get("/api/login/google");
};

export const getUserIntercomHash = (): AxiosPromise => {
    return axios.get("/api/support/intercom-hash");
};

export const getUserExistsById = (id: string): AxiosPromise => {  
    return axios.get(`/api/social/exists/${id}`);
};
