import {Grid,Typography}  from "@mui/material";

const Terms = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>Duration</Typography> <br />
                <Typography variant="base16" sx={{fontWeight: "600", letterSpacing: "0.46px"}}>12 MONTS</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="base" sx={{textTransform: "uppercase"}}>termination DATE</Typography> <br />
                <Typography variant="base16" sx={{color: "rgba(20, 168, 28, 1)", fontWeight: "600", letterSpacing: "0.46px", textTransform: "uppercase"}}>Auto-renewable</Typography> <br />
                <Typography variant="caption" sx={{color: "rgba(32, 39, 46, 0.6)", display: "block"}}>Jun 20, 2026</Typography>
            </Grid>
        </>
    );
};

export default Terms;