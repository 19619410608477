import { ContactSupportOutlined, DriveFileRenameOutlineOutlined, KeyboardArrowRightOutlined, LaunchOutlined, PersonOutlined, YouTube } from "@mui/icons-material";
import {Avatar, Box, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography}  from "@mui/material";
import { Link } from "react-router-dom";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext, useEffect, useState } from "react";
import { Creator } from "@models/User";
import { getCreatorInfo } from "services/UserServices";

const SideMenu = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const [creatorInfo, setCreatorInfo] = useState<Creator>({ gender: '', language: '' } as Creator);
    useEffect(() => {
        if (!creatorInfo.emailAddress1) {
            getCreatorInfo().then((res: any) => {
                setCreatorInfo(res.data);
            });
        }
    }, []);

    const totalSubscribers = creatorInfo.channels ? creatorInfo.channels.reduce((total, channel) => total + channel.subscriberCount, 0): 0;

    return (
        <>
        <Box sx={{display: {xs:"none",md: "block"}, bgcolor: "rgba(245, 245, 245, 1)", p: 2, borderBottom: "1px solid #d8d9df"}}>
            <Typography variant="base20" sx={{ lineHeight: "160%", color: "#20272E", letterSpacing: "0.15px"}}>Profile</Typography>
        </Box>

        <Grid container sx={{mb:1, px: 2, py: 1, display: {xs:"flex",md: "none"}}}>
            <Grid item sx={{width: "40px", textAlign: "center", mr: 2}}>
                <Avatar alt={`${creatorInfo?.firstname} ${creatorInfo?.lastname}}`} src={creatorInfo?.profilePictureUrl ? creatorInfo?.profilePictureUrl : 'javascript:;'}  sx={{display: "inline-block", lineHeight: "38px", verticalAlign: "middle"}}/>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="base16" sx={{display: "block"}}>{creatorInfo?.firstname} {creatorInfo?.lastname}</Typography>
                <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>{totalSubscribers} subscribers</Typography>
            </Grid>
        </Grid>
        <Paper sx={{px: {md: 1, xs: 0}, py: {md: .5, xs: 0}, br: 0.5, position: "sticky", top: "96px", borderRadius: "0 0 4px 4px"}}>
            <MenuList sx={{py: {md: 1, xs: 0}, borderTop: {md: "none", xs:"1px solid #d0d1df"}}}>
                <MenuItem sx={{height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="/home/profile/my-details" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <PersonOutlined />
                    </ListItemIcon>
                    <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}}}>Your details</ListItemText>
                        <KeyboardArrowRightOutlined/>
                </MenuItem>
                <MenuItem sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}, height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="/home/profile/social-accounts" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <YouTube />
                    </ListItemIcon>
                    <ListItemText>Social accounts</ListItemText>
                </MenuItem>
                <MenuItem sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}, height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="/home/profile/contracts" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <DriveFileRenameOutlineOutlined />
                    </ListItemIcon>
                    <ListItemText>Contracts</ListItemText>
                </MenuItem>
                <MenuItem sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}, height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="https://bentpixels.com/about#contact" target="_blank" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <ContactSupportOutlined />
                    </ListItemIcon>
                    <ListItemText>Support</ListItemText>
                    <Typography>
                        <LaunchOutlined sx={{fontSize: "20px", color: "#0000008A", verticalAlign: "middle"}}/>
                    </Typography>
                </MenuItem>
            </MenuList>
        </Paper>
        </>
    );
};

export default SideMenu;
