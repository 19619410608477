import {Grid}  from "@mui/material";
import {
    Routes,
    Route,
} from "react-router-dom";
import MyDetails from "./MyDetails";
import Contracts from "./Contracts";
import SocialAccounts from "./SocialAccounts";
import SideMenu from "./SideMenu";
import ContractDetail from "./Contracts/Detail";
import { CurrentResponsiveInsideFlag } from "../../../hooks/useResponsiveInsideFlag";
import { useContext } from "react";

const Profile = () => {
    const {responsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    return (
        <>
        <Grid container spacing={2} sx={{mb: 2}}>
            <Grid item xs={12} md={4} sx={{display: responsiveInsideFlag ? {xs:"none", md: "block"}: "block"}}>
                <SideMenu/>
            </Grid>
            <Grid item xs={12} md={8} sx={{display: !responsiveInsideFlag ? {xs:"none", md: "block"}: "block"}}>
                <Routes>
                    <Route path="/my-details" element={<MyDetails/>}></Route>
                    <Route path="/social-accounts" element={<SocialAccounts/>}></Route>
                    <Route path="/contracts" element={<Contracts/>}></Route>
                    <Route path="/contracts/*" element={<ContractDetail/>}></Route>
                </Routes>
            </Grid>
        </Grid>
        </>
    );
};

export default Profile;