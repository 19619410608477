import {Box} from "@mui/material";

const Paypal = (props: {sx?:object | null | undefined}) => {
    return (
        <Box sx={{...props.sx}}>
            <svg role="img" aria-label="Bent Pixels Paypal" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>Paypal icon</title>
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M6.48299 4.23694H11.5599C14.2858 4.23694 15.312 5.61692 15.1534 7.64429C14.8913 10.9914 12.8678 12.8431 10.1839 12.8431H8.82882C8.46057 12.8431 8.21288 13.0869 8.11328 13.7474L7.53796 17.5872C7.49996 17.8362 7.3689 17.9804 7.17233 18H3.98252C3.68241 18 3.57626 17.7707 3.65489 17.274L5.5997 4.96428C5.67571 4.47152 5.94568 4.23694 6.48299 4.23694Z" fill="#009EE3"/>
                <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M3.30753 0H8.38972C9.82081 0 11.5192 0.0458682 12.6542 1.04842C13.4129 1.71809 13.8113 2.78354 13.7196 3.93156C13.4077 7.81201 11.0868 9.98616 7.97298 9.98616H5.46727C5.04004 9.98616 4.75828 10.2692 4.63771 11.0346L3.93789 15.4903C3.89202 15.7787 3.76752 15.949 3.54473 15.97H0.408662C0.0613742 15.97 -0.0618158 15.7079 0.02861 15.1286L2.2827 0.846595C2.37313 0.272587 2.68897 0 3.30753 0Z" fill="#113984"/>
                <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M4.71094 10.5824L5.59816 4.96425C5.67548 4.47149 5.94545 4.2356 6.48276 4.2356H11.5597C12.3998 4.2356 13.0799 4.36665 13.612 4.60909C13.1022 8.06362 10.8678 9.98222 7.94268 9.98222H5.4409C5.1054 9.98354 4.85903 10.1513 4.71094 10.5824Z" fill="#172C70"/>    
            </svg>
        </Box>
    );
};

export default Paypal;