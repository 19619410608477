import {Autocomplete, Box, TextField} from "@mui/material";
import {countries} from "../../models/Countries";

interface CountryDropDownProps {
    initialValue: string;
    updateValue: (val: string ) => void;
    error: boolean | undefined;
    onBlur: () => void;
    helperText: string;
    disabled: boolean;
}

const CountryDropDown = (props: CountryDropDownProps) => {
    const getInitialValue = () => {
        const country = countries.find((c) => c.code === props.initialValue);
        return country ? country : null;

    };
    return (
        <Autocomplete
            id="country-select"
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.label}
            fullWidth
            disabled={props.disabled}
            value={getInitialValue()}
            onChange={(event, value) => {
                if (value === null)
                    props.updateValue('');
                else
                    props.updateValue(value.code);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label} ({option.code})
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Country"
                    error={props.error}
                    onBlur={props.onBlur}
                    helperText={props.helperText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
};

export default CountryDropDown;
