import MainStepContainer from "../MainContent/MainStepParts/MainStepContainer";
import {Lock} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import MainStepHeader from "../MainContent/MainStepParts/MainStepHeader";
import GoogleAuthButton from "../GoogleAuthButton/GoogleAuthButton";
import {BentPixelsUserInfo} from "../../../models/BentPixelsUserInfo";
import {AxiosError} from "axios";

interface AuthStepsProps {
    onSuccessfulAuth: (user: BentPixelsUserInfo | undefined) => void;
}

const AuthSteps = (props: AuthStepsProps) => {
    const showErrorAlert = (error: AxiosError) => {
        console.log(error);
    };
    return (
            <MainStepContainer>
                <MainStepHeader>
                    <Lock sx={{color: "rgba(0, 0, 0, 0.54)", pl: 1.5}}/>
                    <Typography sx={{p: 1.5}} variant={'body2'}>Authentication</Typography>
                </MainStepHeader>
                <Box sx={{width: 1, display: "flex", justifyContent: "center", mt: 6, mb: 8}}>
                    <GoogleAuthButton onSuccessfulAuth={props.onSuccessfulAuth} onError={showErrorAlert}/>
                </Box>
            </MainStepContainer>
    );
};

export default AuthSteps;