import {Box} from "@mui/material";

const Ach = (props: {sx?:object | null | undefined}) => {
    return (
        <Box sx={{...props.sx}}>
            <svg role="img" aria-label="Bent Pixels Ach" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>Ach icon</title>
                <path d="M21.37 5.2925L21.71 5.6325C22.1 6.0225 22.1 6.6525 21.71 7.0425L19.88 8.8725L18.13 7.1225L19.96 5.2925C20.35 4.9025 20.98 4.9025 21.37 5.2925Z" fill="black" fillOpacity="0.54"/>
                <path d="M9 18.0034V16.2534L17.06 8.19336L18.81 9.94336L10.75 18.0034H9Z" fill="black" fillOpacity="0.54"/>
                <path d="M4 8H14.5L12.5 10H4V16H6.5V18H4C2.9 18 2 17.1 2 16V10C2 8.9 2.9 8 4 8Z" fill="black" fillOpacity="0.54"/>
                <path d="M20 11.5L18 13.5V16H15.5L13.5 18H18C19.1 18 20 17.1 20 16V11.5Z" fill="black" fillOpacity="0.54"/>    
            </svg>
        </Box>
    );
};
export default Ach;