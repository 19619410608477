//External imports
import {useEffect, useRef} from 'react';
import axios, {AxiosError, AxiosResponse, AxiosRequestConfig} from "axios";
import {Box} from "@mui/material";
import {GOOGLE_CLIENT} from "../../../models/AuthConstants";
import {BentPixelsUserInfo} from "../../../models/BentPixelsUserInfo";
import {loginGoogle} from "../../../services/AuthServices";

// eslint-disable-next-line
const loadScript = (src: any) =>
    new Promise<void>((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve();
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (err) => reject(err);
        document.body.appendChild(script);
    });
// eslint-disable-next-line
declare let google: any;

interface GoogleAuthProps {
    onSuccessfulAuth: (user: BentPixelsUserInfo | undefined) => void;
    onError: (error: AxiosError) => void;
}

const GoogleAuthButton = (props: GoogleAuthProps) => {
    const googleButton = useRef(null);

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client';
        const id = GOOGLE_CLIENT;

        loadScript(src)
            .then(() => {
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                });
                google.accounts.id.renderButton(
                    googleButton.current,
                    {theme: 'outline', size: 'large'}
                );
            })
            .catch(console.error);

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`);
            if (scriptTag) document.body.removeChild(scriptTag);
        };
    }, []);

    function handleCredentialResponse(response: any) {
        loginGoogle(response.credential).then((res: AxiosResponse<BentPixelsUserInfo>) => {
            axios.interceptors.request.use((config: AxiosRequestConfig) => {
                // @ts-ignore
                config.headers.Authorization = `Bearer ${res.data.token}`;
                return config;
            }, (error) => {
                return Promise.reject(error);
            });
            
            props.onSuccessfulAuth(res.data);
        }).catch((err: AxiosError) => {
            props.onError(err);
        });
    }

    return (
        <Box sx={{mx: 4, my: 'auto'}}>
            <div ref={googleButton}></div>
        </Box>
    );
};

export default GoogleAuthButton;