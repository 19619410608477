import { HomeOutlined, WalletOutlined, AnalyticsOutlined, Person2Outlined, PowerSettingsNewOutlined, SettingsOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import {Paper, ListItemIcon, ListItemText, MenuItem, MenuList, Typography, Box}  from "@mui/material";
import "./index.scss";
import DiscordIcon from "./DiscordIcon";
import SkypeIcon from "./SkypeIcon";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CurrentResponsiveMenuFlag } from "../../hooks/useResponsiveFlag";
import { CurrentResponsiveInsideFlag } from "../../hooks/useResponsiveInsideFlag";
import { useSignOut } from 'react-auth-kit';
import TalentManager from "./components/TalentManager";
const AppSideMenu = () => {
    const {setResponsiveMenuFlag} = useContext(CurrentResponsiveMenuFlag);
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const signOut = useSignOut();
    return (
        <>
        <Box sx={{position: "sticky", top: {xs: "48px", md:"96px", zIndex: 1}}}>
            <Paper sx={{borderRadius: {xs: 0, md: "4px"}, margin:{xs: "0", md: "auto"}, px: {xs: 0, md: 1}, py: .5, br: 0.5, bgcolor: {xs:"#0a2ae7", md: "#FFF"}, boxSizing: "border-box", color: {xs: "#fff", md: "rgba(0, 0, 0, 0.87)"}, minHeight: {xs:"420px", md: "0"}, width: {xs: 1, md: "auto"}, zIndex: 1}}>
                <MenuList sx={{paddingTop: {xs: 5, md: 2}, height: {xs: "380px", md: "auto"} }}>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto"}, display: "inline-box"}} component={Link} to="/home/dashboard" onClick={() => {setResponsiveInsideFlag(false);setResponsiveMenuFlag(false);}}>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <HomeOutlined sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}} />
                        </ListItemIcon>
                        <ListItemText sx={{ span: {fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Home</ListItemText>
                            <KeyboardArrowRightOutlined/>
                    </MenuItem>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto", display: "inline-box"}, display: {xs: "none", md: "flex"}}} disabled>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <WalletOutlined  sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}}/>
                        </ListItemIcon>
                        <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis", fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Earnings</ListItemText>
                        <Typography variant="body2">
                            Soon
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto", display: "inline-box"}, display: {xs: "none", md: "flex"}}} disabled>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <AnalyticsOutlined sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}} />
                        </ListItemIcon>
                        <ListItemText sx={{ span: {overflow: "hidden", textOverflow: "ellipsis", fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Analytics</ListItemText>
                        <Typography variant="body2">
                            Soon
                        </Typography>
                    </MenuItem>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto"}, display: "inline-box"}} component={Link} to="/home/profile/my-details" onClick={() => {setResponsiveInsideFlag(false);setResponsiveMenuFlag(false);}}>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <Person2Outlined  sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}}/>
                        </ListItemIcon>
                        <ListItemText sx={{ span: {overflow: "hidden", textOverflow: "ellipsis", fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Profile</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto"}, display: "inline-box"}} component={Link} to="/home/settings/security" onClick={() => {setResponsiveMenuFlag(false);setResponsiveInsideFlag(false);}}>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <SettingsOutlined  sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}}/>
                        </ListItemIcon>
                        <ListItemText sx={{ span: {overflow: "hidden", textOverflow: "ellipsis", fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Settings</ListItemText>
                    </MenuItem>
                    <MenuItem sx={{height: "48px", mb:{xs:2, md:"auto", display: "inline-box"}, position:{xs: "absolute", md: "relative"}, bottom: {xs: "0",md: ""}}} onClick={() => signOut()}>
                        <ListItemIcon sx={{pl:{xs: 5, md: 0}}}>
                            <PowerSettingsNewOutlined  sx={{color: {xs: "#fff", md:"inherit"}, fontSize: {xs: "32px", md:"24px"}}} />
                        </ListItemIcon>
                        <ListItemText sx={{ span: {overflow: "hidden", textOverflow: "ellipsis", fontSize: {xs:"24px", md: "16px"}}, pl: {xs: 3, md:0}}}>Logout</ListItemText>
                    </MenuItem>
                </MenuList>
            </Paper>
            <TalentManager sx={{p: 4, mt: 3, borderRadius: {xs: 0, md:0.5}, pb: {xs:"48px", md:5}}}/>
        </Box>
        </>
    );
};

export default AppSideMenu;