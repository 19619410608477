import {Autocomplete, Box, TextField} from "@mui/material";
import {languages} from "../../models/Languages";

interface LanguageDropDownProps {
    initialValue: number;
    updateValue: (val: number ) => void;
    error: boolean | undefined;
    onBlur: () => void;
    helperText: string;
    disabled: boolean;
}

const LanguagesDropDown = (props: LanguageDropDownProps) => {
    const options = languages;

    const getSelectedLanguage = () => {
        const ig = options.find((g) => g.numCode === props.initialValue);
        return ig ? ig : null;
    };

    return (
        <Autocomplete
            id="language-select"
            options={options}
            autoHighlight
            getOptionLabel={(option) => option.label}
            fullWidth
            value={getSelectedLanguage()}
            disabled={props.disabled}
            onChange={(event, value) => {
                if (value === null)
                    props.updateValue(0);
                else
                    props.updateValue(value.numCode);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.flag.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.flag.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Language"
                    error={props.error}
                    onBlur={props.onBlur}
                    helperText={props.helperText}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />);
};

export default LanguagesDropDown;