import {useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import {GOOGLE_CLIENT} from "../../models/AuthConstants";

interface GoogleAuthProps {
    scopes: string;
    buttonLabel: string;
    onSuccess: (accessToken: string | undefined) => void;
    buttonVariant: 'text' | 'outlined' | 'contained';
    buttonSize: 'small' | 'medium' | 'large';
    loginType: 'youtube' | 'google';
}

interface GoogleAuthResponse {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
}

// this tells typescript that google exists. it does it was loaded on to global
// eslint-disable-next-line
declare let google: any;

const   YoutubeAuthButton = (props: GoogleAuthProps) => {
    // eslint-disable-next-line
    const [client, setClient] = useState<any>();
    const [authenticating, setAuthenticating] = useState(true);

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client';
        const id = GOOGLE_CLIENT;

        // @ts-ignore
        google = window.google;
        const gClient = google.accounts.oauth2.initTokenClient({
            client_id: id,
            callback: handleCredentialResponse,
            scope: props.scopes
        });
        setClient(gClient);
        setAuthenticating(false);

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`);
            if (scriptTag) document.body.removeChild(scriptTag);
        };
    },[]);

    function handleCredentialResponse(response: GoogleAuthResponse) {
        props.onSuccess(response.access_token);
    }

    const padding = props.buttonVariant === 'text' ? 0 : 6;
    return (
        <>
            {props.loginType === 'youtube' ?
                <Button sx={{px: padding, width:{xs: "90%", md: "auto"}}} variant={props.buttonVariant} size={props.buttonSize} disabled={authenticating} onClick={() => {
                    if (client) {
                        client.requestAccessToken();
                    } else {
                        console.error("gClient not loaded");
                    }
                }}>{props.buttonLabel}</Button>
                :
                <Box display={'flex'}>
                    <img style={{backgroundColor: "#1976d2"}} src={"./btn_google_dark_normal_ios.svg"} alt={"blah"}/>
                    <Button sx={{p: 0,
                                textTransform: 'none',
                                width: "287px",
                                boxShadow: "none",
                                borderRadius: 0,
                    backgroundColor: "#1976d2"}}
                            variant={props.buttonVariant}
                            size={props.buttonSize}
                            disabled={authenticating}
                            onClick={() => {
                                                client.requestAccessToken();
                                            }}
                    >{props.buttonLabel}</Button>
                </Box>
            }
        </>
    );
};

export default YoutubeAuthButton;