import {Box} from "@mui/material";

const Qenta = (props: {sx?:object | null | undefined}) => {
    return (
        <Box sx={{...props.sx}}>
            <svg role="img" aria-label="Bent Pixels Qenta" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>Discord icon</title>
                <path d="M11.3477 16.6221H18.3376V23.1595L14.717 19.7399C14.2308 19.8908 12.967 20.1925 11.8003 20.1925C3.65374 19.8908 3 13.8563 3 12.0963C3 10.3362 4.15661 4 11.8003 4C19.444 4 20.5 10.4368 20.5 12.0963C20.5 13.4239 20.0642 14.6609 19.8463 15.1135H15.069C15.069 15.1135 16.0244 13.5043 16.0244 12.0963C15.7227 7.87213 12.8563 7.5704 11.8003 7.5704C10.7443 7.5704 7.52586 7.92241 7.52586 12.0963C7.52586 15.4353 10.0738 16.5048 11.3477 16.6221Z" fill="#FDB714"/>
            </svg>
        </Box>
    );
};

export default Qenta;