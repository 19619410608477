import { AddCircleOutlined, ChevronLeft, RefreshOutlined, YouTube } from "@mui/icons-material";
import {Avatar, Button, Grid, Paper, Typography}  from "@mui/material";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext, useEffect, useState } from "react";
import { Creator } from "@models/User";
import { getCreatorInfo } from "services/UserServices";
import { Link } from "react-router-dom";

const SocialAccounts = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const [creatorInfo, setCreatorInfo] = useState<Creator>({ gender: '', language: '' } as Creator);
    
    useEffect(() => {
        if (!creatorInfo.emailAddress1) {
            getCreatorInfo().then((res: any) => {
                setCreatorInfo(res.data);
            });
        }
    }, []);

    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Social Accounts
            <Button variant="contained" component={Link} to="/onboarding" target="_blank" sx={{position: "absolute", top: 6, right:8}} startIcon={<AddCircleOutlined />}>
                <Typography sx={{display: {xs: "none", md: "inline"}, mr: "5px"}}>ADD</Typography>{"Account"}
            </Button>
        </Typography>
        <Paper sx={{p: {md:3, xs: 2}, br: 0.5}}>
            <Typography variant="base20" sx={{display: {md:"flex", xs: "none"}, mt: 2, mb: 3, justifyContent: "space-between", }}>
                Social Accounts
                <Button variant="contained"  component={Link} to="/onboarding" target="_blank" startIcon={<AddCircleOutlined />}>
                    <Typography sx={{display: {xs: "none", md: "inline"}, mr: "5px"}}>ADD</Typography>{"Account"}
                </Button>
            </Typography>
            <Paper sx={{background: "rgba(10, 42, 231, 0.08)"}}>
                <Typography variant="body2" sx={{p:1}}>
                    <YouTube sx={{color: "rgba(224, 47, 47, 1)", verticalAlign: "middle", mr: 1}}/>
                    Youtube
                </Typography>
            </Paper>
            {
                creatorInfo.channels?.map((channel, index) => {
                    return (
                        <Grid container sx={{justifyContent: "space-between",mt:2, p: 1}} key={index}>
                            <Grid item sx={{textAlign: "center", mr: {xs: 1, md: 2}}}>
                                <Avatar alt={channel.channelName.toUpperCase()} src={channel.channelName} sx={{display: "inline-block", lineHeight: "38px", verticalAlign: "middle"}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="base16" sx={{display: "block"}}>{channel.channelName}</Typography>
                                
                                <Typography variant={'caption'} sx={{color: "rgba(32, 39, 46, 0.6)"}}>{channel.subscriberCount} subscribers</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: "right"}}>
                                <Button variant="outlined" color="success" size="small" sx={{fontSize:"10px", px: 1, py: "4px", mt: 1, padding: "0px 8px"}}>Connected</Button>
                            </Grid>
                        </Grid>
                    );
                })
            }
        </Paper>
        </>
    );
};

export default SocialAccounts;