import { Box, Grid, Paper, Typography } from "@mui/material";
import { InfoOutlined} from "@mui/icons-material";
import { BarChart } from '@mui/x-charts/BarChart';
import { getCreatorEarning } from "services/PaymentServices";

const YoutubeEarnings = () => {

  getCreatorEarning().then((res: any) => {
    console.log(res.data);
  });

  return (
    <Grid item width={1}>
        <Paper sx={{ p: 3, br: 0.5, width: "100%", height: "fit-content",  mb: "3", boxSizing: "border-box" }}>
          <Typography variant="base20">
            Youtube Earnings
            <span style={{verticalAlign: 'text-bottom'}}>
              <InfoOutlined sx={{color: '#AAADB0', verticalAlign: 'middle', display: 'inline-block', ml: 1}}/>
            </span>
          </Typography>
          <br />
          <Typography variant="base" sx={{mt: 1}}>
            Your past 6 months YT earnings have gone up by 3% MoM. You could be making more! Check out our tools or talk to your Talent Manager!
          </Typography>
          <Box sx={{my:2, ml: '-32px', position: 'relative', div: {maxWidth: "100%"}}}>
            <BarChart
                xAxis={[{ scaleType: 'band', data: ['May ‘23', 'Apr ‘23', 'Mar ‘23', 'Feb ‘23', 'Jan ‘23', 'Dec ‘22'], id: 'bottomAxisId' }]}
                yAxis={[ { id: 'rightAxisId', disableLine: true,}]}
                series={[{ data: [18000, 16000, 10500, 17400, 19000, 8000], yAxisKey: 'rightAxisId', color: "#0A2AE7" }]}
                rightAxis={{ axisId: 'rightAxisId', disableLine: true, disableTicks: true,}}
                bottomAxis={{ axisId: 'bottomAxisId', disableLine: true, disableTicks: true,}}
                leftAxis={null}
                width={500}
                height={300}
              />
          </Box>
      </Paper>
    </Grid>
  );
};

export default YoutubeEarnings;
