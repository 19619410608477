import { ChevronLeft, InfoOutlined } from "@mui/icons-material";
import {FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography}  from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import DiscordIcon from "../../../../components/AppSideMenu/DiscordIcon";
import SkypeIcon from "../../../../components/AppSideMenu/SkypeIcon";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { AxiosResponse } from "axios";
import { getCreatorInfo, updateCreatorInfo } from "services/UserServices";
import { Creator, UserInfo } from "@models/User";
import { debounce } from "lodash";
import { CurrentMainSnackbar } from "hooks/useMainSnackbar";

const MyDetails = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    const [gender, setGender] = useState('');
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [creatorInfo, setCreatorInfo] = useState<Creator>({ gender: '', language: '' } as Creator);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const { name, value } = event.target;
        setCreatorInfo((prevState: any) => {
            const updatedState = { ...prevState, [name as string]: value };
            return updatedState;
        });
    };
    const handleChangeSelect = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setCreatorInfo((prevState: any) => ({
            ...prevState,
            [name as string]: value
        }));
    };

    useEffect(() => {
        const debouncedUpdate = debounce(() => {
            updateCreatorInfo(creatorInfo).then((res: AxiosResponse<Creator>) => {
                console.log(res.data);
                updateMainSnackbar(true, "success", "Updated...", 1000);
            });
        }, 500);

        debouncedUpdate();

        return () => {
            debouncedUpdate.cancel();
        };
    }, [creatorInfo]);

    useEffect(() => {
        getCreatorInfo().then((res: AxiosResponse<Creator>) => {
            setCreatorInfo(res.data);
        });
    }, []);


    useEffect(() => {
        getCreatorInfo().then((res: AxiosResponse<Creator>) => {
            setCreatorInfo(res.data);
        });
    },[]);

    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Your details
        </Typography>
        <Paper sx={{p: 3, br: 0.5}}>
            <Paper elevation={0} sx={{background: "#e4f1fc", padding: "15px 18px 6px 46px", position: "relative"}}>
                <InfoOutlined sx={{color: "#0074DF", mr: "12px", verticalAlign: "middle", fontSize: "22px", position: "absolute", top: "16px", left: "16px"}}/>
                <Typography variant="base16">
                    Changes performed?
                </Typography>
                <br />
                <Typography variant="base" sx={{lineHeight: "143%", display: "inline-block", mt: "4px", mb: "4px"}}>
                    Your data will be automatically saved when you perform any action, so don’t worry you can leave this page at any time
                </Typography>
            </Paper>
            <Typography variant="base20" sx={{display: "block", mt: 2, mb: 3}}>
                Detail information
            </Typography>

            <Typography variant="body2" sx={{color: "#20272E"}}>
                Personal information
            </Typography>
            <br />
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField label="First Name" variant="outlined" fullWidth name="firstname" value={creatorInfo.firstname} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField label="Last Name" variant="outlined" fullWidth name="lastname" value={creatorInfo.lastname} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <DatePicker
                    label="Date of birth (mandatory)"
                    onChange={()=>false}
                    value={dayjs(creatorInfo.birthDate).format('DD/MM/YYYY')}
                    renderInput={(params) => <TextField
                        {...params}
                        fullWidth/>} />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <InputLabel id="demo-simple-select-label">Gender (mandatory)</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={creatorInfo.gender}
                    label="Gender (mandatory)"
                    name="gender"
                    onChange={handleChangeSelect} fullWidth
                >
                    <MenuItem value={"1"}>Male</MenuItem>
                    <MenuItem value={"2"}>Female</MenuItem>
                    <MenuItem value={"121260000"}>Other</MenuItem>
                </Select>
            </FormControl>
            

            <Typography variant="body2" sx={{color: "#20272E", mt: 3}}>
                Contact information
            </Typography>
            <br />
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField  label="Primary email" variant="outlined" fullWidth name="emailAddress1" value={creatorInfo.emailAddress1} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField label="Contact email" variant="outlined" fullWidth name="emailAddress2" value={creatorInfo.emailAddress2} placeholder="" onChange={handleChange} InputLabelProps={{shrink: true,}} />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <InputLabel id="demo-simple-select-label">Language (mandatory)</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={creatorInfo.language}
                    label="Language (mandatory)"
                    name="language"
                    onChange={handleChangeSelect} fullWidth
                >
                    <MenuItem value={"121260000"}>English</MenuItem>
                    <MenuItem value={"121260001"}>Spanish</MenuItem>
                    <MenuItem value={"121260002"}>French</MenuItem>
                    <MenuItem value={"121260003"}>Portuguese</MenuItem>
                    <MenuItem value={"121260004"}>German</MenuItem>
                    <MenuItem value={"O"}>Other</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField label="Phone number (mandatory)" variant="outlined" fullWidth name="telephone" value={creatorInfo.telephone} placeholder={'e.g. +01 000 0000000'} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
            <hr />
            <FormControl fullWidth sx={{ my: 2}}>
                <TextField label="Skype" variant="outlined" fullWidth name="skypeId" value={creatorInfo.skypeId} placeholder={'fernanFlux'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ path: {fill: "rgba(0, 175, 240, 1) !important"}, marginTop: "5px" }} >
                            <SkypeIcon sx={{verticalAling: "middle"}} />
                        </InputAdornment>
                    ),
                }} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2}}>
                <TextField label="Discord" variant="outlined" fullWidth name="discordId" value={creatorInfo.discordId} placeholder={'fernanFlux'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ path: {fill: "rgba(88, 101, 242, 1) !important"}, marginTop: "5px" }}>
                            <DiscordIcon sx={{verticalAling: "middle"}}/>
                        </InputAdornment>
                    ),
                }} onChange={handleChange} InputLabelProps={{shrink: true,}}/>
            </FormControl>
        </Paper>
        </>
    );
};

export default MyDetails;