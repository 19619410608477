import { Box, Grid } from "@mui/material";
import WifiOffFilled from "@mui/icons-material/WifiOff";
import WifiFindOutlined from "@mui/icons-material/WifiFindOutlined";
import { useEffect, useState } from "react";

const OffLineIndicator = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isLowConnection, setIsLowConnection] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    // @ts-ignore: Unreachable code error
    setIsLowConnection(window.navigator.connection.effectiveType.endsWith("2g"));

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <>
      {!isOnline ? (
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#EEE",
              px: "16px",
              height: "32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <WifiOffFilled sx={{ color: "#0000008A", fontSize: "16px" }} />
            <span
              style={{ color: "#20272E", fontSize: "12px", marginLeft: "8px" }}
            >
              You are offline, check your data or wifi connection.
            </span>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
      {isLowConnection && isOnline ? (
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#EEE",
              px: "16px",
              height: "32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <WifiFindOutlined sx={{ color: "#0000008A", fontSize: "16px" }} />
            <span
              style={{ color: "#20272E", fontSize: "12px", marginLeft: "8px" }}
            >
              Low internet connection
            </span>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default OffLineIndicator;
