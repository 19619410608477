import {CreditCardOutlined, FileDownloadOutlined, KeyboardArrowRightOutlined, LockOutlined, Shield} from "@mui/icons-material";
import {Box, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Typography}  from "@mui/material";
import { Link } from "react-router-dom";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";
import { useContext } from "react";

const SideMenu = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    return (
        <>
        <Box sx={{p: {md: 2, xs:"0 16px 8px 16px"}, borderBottom: "1px solid #d8d9df", background: {xs: "transparent", md: "rgba(245, 245, 245, 1)"}}}>
            <Typography variant="base20" sx={{lineHeight: "160%", color: "#20272E", letterSpacing: "0.15px", fontSize: {md: "20px", xs: "34px"}, fontWeight: {md: "500", xs: "600"}}}>Settings</Typography>
        </Box>
        <Paper sx={{px: {md: 1, xs: 0}, py: {md: .5, xs: 0}, br: 0.5, position: "sticky", top: "96px", borderRadius: "0 0 4px 4px"}}>
            <MenuList sx={{py: {md: 1, xs: 0}, borderTop: {md: "none", xs:"1px solid #d0d1df"}}}>
                <MenuItem sx={{height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="./security" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <Shield />
                    </ListItemIcon>
                    <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}}}>Security</ListItemText>
                        <KeyboardArrowRightOutlined/>
                </MenuItem>
                <MenuItem sx={{height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="./data-and-privacy" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <LockOutlined />
                    </ListItemIcon>
                    <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}}}>Data & privacy</ListItemText>
                </MenuItem>
                <MenuItem sx={{height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="./download-your-data" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <FileDownloadOutlined />
                    </ListItemIcon>
                    <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}}}>Download your data</ListItemText>
                </MenuItem>
                <MenuItem sx={{height: {md:"48px", xs:"56px"}, borderBottom: {md: "none", xs: "1px solid #d0d1df"}}} component={Link} to="./payment-method" onClick={() => setResponsiveInsideFlag(true)}>
                    <ListItemIcon>
                        <CreditCardOutlined />
                    </ListItemIcon>
                    <ListItemText sx={{span: {overflow: "hidden", textOverflow: "ellipsis"}}}>Payment method</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
        </>
    );
};

export default SideMenu;