import {createContext, useState} from 'react';

interface responsiveFlag {
    responsiveMenuFlag: boolean
    setResponsiveMenuFlag: (responsiveMenuFlag: boolean) => void
}

export const CurrentResponsiveMenuFlag = createContext<responsiveFlag>({responsiveMenuFlag: false, setResponsiveMenuFlag: () => undefined});

export const useResponsiveMenuFlag = () => {
    const [responsiveMenuFlag, setFlag] = useState(false);
    const setResponsiveMenuFlag = (newFlag: boolean) => {
        setFlag(newFlag);
    };
    return {responsiveMenuFlag, setResponsiveMenuFlag};
};