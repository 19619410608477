import {Box, Grid} from "@mui/material";
import StepInstructions from "./StepInstructions/StepInstructions";
import StepFooter from "./StepFooter/StepFooter";
const LeftPanel = () => {
    return (
        <Box sx={{borderRight: "1px solid #3550F71F", height: {xs: "auto", md: "100%"}, background: '#fff'}}>
            <Grid container direction='column' sx={{height: "100%", pt: {xs: 6, md:0}}}>
                <Grid item flexGrow={1} sx={{width:{xs: "100vw", md: "inherit"}}}>
                    <StepInstructions />
                </Grid>
                <Grid item>
                    <StepFooter />
                </Grid>
            </Grid>
        </Box>
    );

};

export default LeftPanel;