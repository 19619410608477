import {createContext, useState} from 'react';

export type reviewSteps = 'about' | 'youtube';
interface selectedReviewStep {
    reviewStep: reviewSteps;
    updateReviewStep: (rs: reviewSteps) => void;

}

export const CurrentReviewStep = createContext<selectedReviewStep>({reviewStep: 'about', updateReviewStep: () => undefined});

export const useInfoStepReview = () => {
    const [reviewStep, setReviewStep] = useState<reviewSteps>('about');
    const updateReviewStep = (rs: reviewSteps) => {
        setReviewStep(rs);
    };
    return {reviewStep, updateReviewStep};
};