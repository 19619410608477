import {Box, Button, Dialog, Typography} from "@mui/material";
// applies to custom application links (ones containing applicationId in the url params)

export type ErrorType = "notFound" | "alreadySubmitted" | "unknown" | "applicationSubmission" | "channelSubmission";

interface CustomApplicationErrorProps {
    open: boolean;
    handleClose: (s: string) => void;
    applicationId: string | null;
    errorType: ErrorType | null;
}
const CustomApplicationError = (props: CustomApplicationErrorProps) => {
    const {open , handleClose, applicationId, errorType} = props;
    let errorTitle;
    let errorMessage;

    if (errorType === "notFound") {
        errorTitle = "Application Not Found";
        errorMessage = `Invalid custom application link (for ${applicationId}).  Please check link or contact support. Redirecting to the Creator Portal login page.`;
    } else if (errorType === "alreadySubmitted") {
        errorTitle = "Active Partner Application Pending";
        errorMessage = `The application from this link (${applicationId}) has already been submitted. Redirecting to the New Application page.`;
    } else if (errorType === "applicationSubmission") {
        errorTitle = "Application Submission Error";
        errorMessage = "An error occurred while submitting your application.  Please try again later or contact support.";
    } else if (errorType === "channelSubmission") {
        errorTitle = "Channel Submission Error";
        errorMessage = "Your personal application data was received successfully but an error occurred while processing your YT Channels.  Please contact support.";
    } else { // unknown error
        errorTitle = "Error Getting Application Information";
        errorMessage = "Unexpected error getting application data.  Please try again later or contact support.";
    }
    const typesHandle = (event: object, reason: string) => {
        if (errorType === "notFound") {
            setTimeout(() => {
                window.location.assign('/');
            }, 500);
            handleClose("buttonevent");
        } else if (errorType === "alreadySubmitted") {
            setTimeout(() => {
                const newUrl = window.location.pathname;
                window.history.replaceState({}, document.title, newUrl);
            }, 500);
            handleClose("buttonevent");
        } else {
            handleClose("buttonevent");
        }
    };
    return (
        <Dialog
            open={open}
            onClose={typesHandle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{py: 2, px: 3}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {errorTitle}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3, mb: 2 }}>
                    {errorMessage}
                </Typography>
                <Button sx={{float: "right"}} variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement>) => typesHandle(event, "")}>GOT IT</Button>
            </Box>
        </Dialog>
    );
};

export default CustomApplicationError;