import {Grid} from "@mui/material";
import LeftPanel from "./components/LeftPanel/LeftPanel";
import MainPanel from "./components/MainPanel/MainPanel";
import {useLastStep} from "./hooks/useLastStep";
import {CurrentReviewStep, useInfoStepReview} from "./hooks/useInfoStepReview";
import Header from "./components/MainComponents/Header/Header";

function Onboarding() {
    const {lastStep} = useLastStep();
    const {reviewStep, updateReviewStep} = useInfoStepReview();
    return (
        <CurrentReviewStep.Provider value={{reviewStep, updateReviewStep}}>
            <Header/>
            <Grid container sx={{height: {xs:"auto", md:"calc(100% - 81px)"}, overflow:{xs:"auto",md:"unset"}, pb:{xs:"68px",md:"0"}, maxHeight: "100%"}}>
                {lastStep ?
                    <></>
                    :
                    <Grid item xs={12} md={3} sx={{flexBasis:{xs:"min-content", md:"100%"}}}>
                        <LeftPanel/>
                    </Grid>
                }
                <Grid item xs={12} md={lastStep ? 12 : 9}>
                    <MainPanel/>
                </Grid>
            </Grid>
        </CurrentReviewStep.Provider>
    );
}

export default Onboarding;
