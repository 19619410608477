import {useContext} from 'react';
import {Box, Button, Step, Stepper} from "@mui/material";
import {CurrentStepContext} from "../../../hooks/useStep";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLastStep} from "../../../hooks/useLastStep"; 
import {StepControl, steps} from "../../LeftPanel/StepInstructions/StepInstructionList";
import './footer.css';

interface FooterProps {
    continueEnabled?: boolean
    shouldSubmit: (s: boolean) => void;
}

const Footer = (props: FooterProps) => {
    const {continueEnabled = true} = props;
    const {step, updateStep} = useContext(CurrentStepContext);
    const {secondToLastStep} = useLastStep();

    const previousStep = () => {
        updateStep(step - 1);
    };

    const nextStep = () => {
        updateStep(step + 1);
        if (secondToLastStep) {
            props.shouldSubmit(true);
        }
    };

    return (
        <Box sx={{height: 68, px:{xs:2,md:5}, display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: 'white'}}>
            {step === 0 ? <div style={{visibility: 'hidden', width: '104px'}}></div> :
                <Button disabled={step === 0} onClick={previousStep} sx={{width: "104px"}}>
                    <ArrowBackIcon sx={{pr: 1}} fontSize='small'/> 
                    Back
                </Button>
            }
            <Stepper 
                sx={{width: "40%", margin: "0 auto", display: {xs:"flex", md:"none"}, justifyContent:"center"}}
                activeStep={step}
                className="footerStepper">
                {steps.map((stepDetails: StepControl, index) => {
                    return (
                    <Step key={stepDetails.stepperName} completed={step > index}>
                        <div className="dot"></div>
                    </Step>);
                })}
            </Stepper>
            {step === 1 ? <div style={{visibility: 'hidden', width: '104px'}}></div> : <Button variant={secondToLastStep ? 'contained' : 'outlined'}
                                          onClick={nextStep}
                                          disabled={!continueEnabled}
                                          sx={{width: "104px"}}
            >
                {secondToLastStep ? 'Submit' : 'Continue'}
            </Button>
            }
        </Box>
    );
};

export default Footer;