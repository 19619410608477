import {createContext, useState} from 'react';
import {steps} from "../components/LeftPanel/StepInstructions/StepInstructionList";

interface currentStep {
    step: number
    updateStep: (step: number) => void
}

export const CurrentStepContext = createContext<currentStep>({step: 0, updateStep: () => undefined});

export const useStep = () => {
    const [step, setStep] = useState(0);
    const updateStep = (nextStep: number) => {
        if (nextStep < 0) {
            return;
        }
        if (nextStep < steps.length) {
            setStep(nextStep);
        }
    };
    return {step, updateStep};
};