import {useContext} from "react";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {CurrentReviewStep, reviewSteps} from "../../../../hooks/useInfoStepReview";

const InfoReviewSelector = () => {
    const {reviewStep, updateReviewStep} = useContext(CurrentReviewStep);
    const handleChange = (event: React.SyntheticEvent, newValue: reviewSteps) => {
        updateReviewStep(newValue);
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    function a11yProps(index: number) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return (
        <Tabs
            orientation={isSmallScreen ? "horizontal":"vertical"}
            variant={isSmallScreen ? "fullWidth":"scrollable"}
            value={reviewStep}
            onChange={handleChange}
            aria-label="Information review tabs"
            TabIndicatorProps={{sx:{left: 0}}}
            sx={{
                width: {xs: "calc(100% + 16px)", md:1 },
                pt: {xs: 0, md: 6},
                mt:{xs: "-16px", md:0},
                ml:{xs: "-16px", md:0},
                pb:{xs: 2, md:0}
            }}
        >
            <Tab sx={{width: 1, maxWidth: 1}} value={'about'} label={isSmallScreen ? "Information":"Basic Information"} {...a11yProps(0)} />
            <Tab sx={{width: 1, maxWidth: 1}} value={'youtube'} label={isSmallScreen ? "Accounts":"Connected Accounts"} {...a11yProps(1)} />
        </Tabs>
    );
};

export default InfoReviewSelector;