import { ChevronLeft } from "@mui/icons-material";
import {Paper, Typography}  from "@mui/material";
import { useContext } from "react";
// import Paypal from "../../../../components/icons/Paypal";
// import Wire from "../../../../components/icons/Wire";
// import Ach from "../../../../components/icons/Ach";
// import Qenta from "../../../../components/icons/Qenta";
import PaymentMethodEdit from "./PaymentMethodEdit";
import { CurrentResponsiveInsideFlag } from "../../../../hooks/useResponsiveInsideFlag";

// const options = [
//   { value: 'paypal', label: 'Paypal', icon: <Paypal sx={{width: "15px", height: "20px"}}/> },
//   { value: 'wiretransfer', label: 'Wire Transfer', icon: <Wire />},
//   { value: 'ach', label: 'ACH', icon: <Ach />},
//   { value: 'check', label: 'Check', icon: <Check />},
//   { value: 'qentaWallet', label: 'Wallet', icon: <Qenta />},
// ];

const PaymentMethod = () => {
    const {setResponsiveInsideFlag} = useContext(CurrentResponsiveInsideFlag);
    // const [selectedOption, setSelectedOption] = useState('');

    // const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    //     setSelectedOption(event.target.value);
    // };

    return (
        <>
        <Typography onClick={()=> {setResponsiveInsideFlag(false);}} sx={{display: {xs: "flex", md: "none"}, mt: "-15px", height: "48px", p:"12px 8px", boxSizing: "border-box", background: "#F5F5F5", fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px"}}>
            <ChevronLeft sx={{mr: 1}} /> Payment method
        </Typography>
        <Paper sx={{p: 3, br: 0.5,}}>
            <PaymentMethodEdit />
        </Paper>
        </>
    );
};

export default PaymentMethod;