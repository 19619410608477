import {YoutubeSummary} from "../../../models/YoutubeSummary";
import {Alert, AlertTitle, Paper} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface RequiredChannelsProps {
    requiredChannels: YoutubeSummary[]; 
    selectedChannels: YoutubeSummary[];
}

const RequiredChannels = (props: RequiredChannelsProps) => {
    return (
        <>
            <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="warning" sx={{marginTop: 4}}>
                <AlertTitle>Custom Link Application</AlertTitle>
                We need the channels below to continue: <br />
                <ul>
                    {props.requiredChannels.map(c => (
                    <li key={c.channelId}>
                        {c.channelName}
                        {props.selectedChannels.some(selectedChannel => selectedChannel.channelId === c.channelId) && <span> <CheckIcon/> </span>}
                    </li>))}
                </ul>
            </Alert>
        </>
    );
};

export default RequiredChannels;