import {Box, Button, Dialog, Typography} from "@mui/material";

interface AccountExistsProps {
    open: boolean;
    handleClose: (s: string) => void;
    channelName: string;
}
const AccountExists = (props: AccountExistsProps) => {
    const {open , handleClose, channelName} = props;
    return (
        <Dialog
            open={open}
            onClose={(event: object, reason: string) => handleClose(reason)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{py: 2, px: 3}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Active partner application pending
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3, mb: 2 }}>
                    The account, {channelName}, already has an application in a pending state.
                </Typography>
                <Button sx={{float: "right"}} variant={"contained"} onClick={() => handleClose("buttonClick")}>GOT IT</Button>
            </Box>
        </Dialog>
    );
};

export default AccountExists;