import {Box} from "@mui/material";

const DiscordIcon = (props: {sx?:object | null | undefined}) => {
    return (
        <Box sx={{...props.sx}}>
            <svg role="img" aria-label="Bent Pixels DiscordIcon" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>Discord icon</title>
                <path d="M18.2477 6.14252C17.0651 5.60109 15.8166 5.21698 14.5342 5C14.3587 5.31372 14.1999 5.63649 14.0585 5.96696C12.6924 5.76111 11.3033 5.76111 9.93721 5.96696C9.79571 5.63652 9.63693 5.31375 9.46151 5C8.17825 5.21881 6.92895 5.60383 5.74514 6.14535C3.39498 9.62245 2.75789 13.0132 3.07644 16.3558C4.45275 17.3727 5.99324 18.146 7.63094 18.6423C7.9997 18.1463 8.326 17.6201 8.60639 17.0693C8.07383 16.8704 7.55982 16.625 7.07029 16.336C7.19913 16.2425 7.32513 16.1463 7.44689 16.0528C8.87128 16.7227 10.4259 17.07 12 17.07C13.574 17.07 15.1287 16.7227 16.5531 16.0528C16.6762 16.1534 16.8022 16.2496 16.9296 16.336C16.4392 16.6255 15.9242 16.8714 15.3907 17.0708C15.6708 17.6213 15.9971 18.147 16.3662 18.6423C18.0053 18.148 19.5469 17.375 20.9235 16.3572C21.2973 12.4809 20.285 9.12127 18.2477 6.14252ZM9.00988 14.3001C8.1222 14.3001 7.38884 13.4946 7.38884 12.5035C7.38884 11.5125 8.09672 10.6998 9.00705 10.6998C9.91739 10.6998 10.6451 11.5125 10.6295 12.5035C10.6139 13.4946 9.91456 14.3001 9.00988 14.3001ZM14.9901 14.3001C14.101 14.3001 13.3704 13.4946 13.3704 12.5035C13.3704 11.5125 14.0783 10.6998 14.9901 10.6998C15.9018 10.6998 16.6238 11.5125 16.6083 12.5035C16.5927 13.4946 15.8947 14.3001 14.9901 14.3001Z" fill="#20272E"/>
            </svg>
        </Box>
    );
};

export default DiscordIcon;