import { Box, Grid } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from "react-router-dom";

interface HeaderAlertProps {
    text: string;
    url?: string;
    emoji?: string;
    show: boolean;
    bgColor?: string;
}

const HeaderAlert = (props: HeaderAlertProps) => {

  return (
    <>
      {props.show ? (
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#FFC107" || props.bgColor,
              px: "24px",
              height: "54px",
              display: "flex",
              boxSizing: "borderBox",
              alignItems: "center",
              position: "relative",
              color: "#20272E",
              fontSize: "20px",
              cursor: "pointer", textDecoration: "none"
            }}
            component={Link} to="https://bentpixels.com/about#contact" target="_blank"
          >
            {props.emoji}
            <span
              style={{ color: "#20272E", fontSize: "14px", marginLeft: "16px" }}
            >
              {props.text}
            </span>
            <ArrowForwardIosIcon style={{position: "absolute", top: "20px", right: "24px", fontSize: "16px"}}/>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderAlert;
